<template>
	<div style="background: #EFF7FD">
		<div class="back">
			<img :src="topImg" alt="">
		</div>
		<div class="navigation">
			<div class="piece" @click="goDingyue(1)">
				<img class="court" src="../../assets/eventtheme/court.png" alt="">
				<p class="name">专题首页</p>
			</div>
			<div class="piece" v-for="(item,index) in navList" :key="index" @click="goDingyue(index+2,item.name,item)">
				<img class="court" :src="item.imgUrl" alt="">
				<p class="name">{{item.name}}</p>
			</div>
		</div>
		<div class="return" @click="homepage">
			<img src="../../assets/image/logo.png" alt="">
			<p class="home">返回首页</p>
		</div>
		<div v-if="display">
			<div class="carousel" style="min-height: 459PX;">
				<div class="lun" v-show="cards && cards.length > 0">
					<carousel :imgList="cards" :type="2" width="643" height="459PX" borderradius="16PX" @getclick="jump" background="rgb(0, 0, 0,0.38)"></carousel>
					<!-- <div class="main">
						<div class="lun_bo">
							<img v-for="(item,index) in cards" :key="index" :src="item.imgUrl" alt=""
								@click="jump(item.id)">
						</div>
						<div class="btn_demo">
							<div class="desc"></div>
							<div class="btn_disc">
								<a v-for="(item,index) in cards2" :key="index" :id="index + 1" class="circle"></a>
							</div>
						</div>
					</div> -->
				</div>
				<div class="block_8 flex-col" v-if="allData && allData[0] && allData[0].length > 0">
					<div class="group_8 flex-row">
						<div class="rectangle" v-for="(item,index) in allData[0]" :key="index" v-if="index<2"
							@click="getnav(index + 1,item)" :style="{'color':navindex == index + 1 ? '#fff':'#000',}">
							<p class="name">{{item.name}}</p>
							<img class="img" :src="navindex == index + 1 ? achievement1: achievement" alt="">
						</div>
					</div>
					<div class="box">
						<div v-for="(item,index) in articles" v-if="index<7" :key="index" @click="jump(item.id)">
							<div class="box_31 flex-row">
								<div style="display: flex;width: 400PX;align-items: center;">
									<img class="thumbnail_8" referrerpolicy="no-referrer"
										src="../../assets/image/titleicon.png" />
									<el-tooltip class="item" effect="dark" :content="item.title" placement="top-start">
									    <span class="text_20"><a>{{item.title}}</a></span>
									</el-tooltip>
								</div>
								<span class="text_21">{{item.publishTime | format}}</span>
							</div>
							<div class="image_9"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="statistics">
				<div class="title">
					<p class="name">获奖情况统计</p>
					<p class="date">统计截止：{{ statisticalTime }}</p>
				</div>
				<div class="table2">
					<el-table :data="tableData" border style="width: 100%;"
						:header-cell-style="{ background: 'rgba(0, 173, 249, 0.38)' }"
						:row-class-name="tableRowClassName">
						<el-table-column prop="areaName" label="单位" align="center">

						</el-table-column>
						<el-table-column prop="name" label="第一名" align="center">
							<template slot="header" slot-scope="scope">
								<div class="custom-header">
									<img src="../../assets/eventtheme/one.png" alt="">
									{{ scope.column.label }}
								</div>
							</template>
							<template slot-scope="scope">
								{{ scope.row.first}}
							</template>
						</el-table-column>
						<el-table-column prop="name" label="第二名" align="center">
							<template slot="header" slot-scope="scope">
								<div class="custom-header">
									<img src="../../assets/eventtheme/two.png" alt="">
									{{ scope.column.label }}
								</div>
							</template>
							<template slot-scope="scope">
								{{ scope.row.second}}
							</template>
						</el-table-column>
						<el-table-column prop="name" label="第三名" align="center">
							<template slot="header" slot-scope="scope">
								<div class="custom-header">
									<img src="../../assets/eventtheme/three.png" alt="">
									{{ scope.column.label }}
								</div>
							</template>
							<template slot-scope="scope">
								{{ scope.row.third}}
							</template>
						</el-table-column>
						<el-table-column prop="fourth" label="第四名" align="center">
						</el-table-column>
						<el-table-column prop="fifth" label="第五名" align="center">
						</el-table-column>
						<el-table-column prop="sixth" label="第六名" align="center">
						</el-table-column>
						<el-table-column prop="seventh" label="第七名" align="center">
						</el-table-column>
						<el-table-column prop="eighth" label="第八名" align="center">
						</el-table-column>
						<el-table-column prop="total" label="总计" align="center">
						</el-table-column>
					</el-table>
				</div>
				<img src="../../assets/eventtheme/statistics.png" alt="">
			</div>

			<div class="statistics">
				<div class="title">
					<p class="name">竞赛日历</p>
				</div>
				<div class="calendar-box">
					<div class="calendar">
						<div class="flrex">
							<div class="information" v-for="(item,index) in mchList" :key="index">
								<div class="rubric">
									<div class="rubricbox">
										{{item.name}}
									</div>
								</div>
								<div class="date">
									<div class="round"></div>
									时间：
									<div v-if="item.isJSON">
										<div v-for="(item,index) in item.start_time" style="font-size: 24PX;" >
										  {{getTime(item)}}
										</div>
									</div>
									<div v-if="!item.isJSON">
										{{item.start_time}} - {{ item.end_time}}
									</div>
								</div>
								<div class="location">
									<div class="round"></div>
									<div class="roundtext">地点：{{item.address}}</div>
								</div>
							</div>
						</div>
						<div class="assembly">
							<Calendar @getDate="getDate" :infoDate="infoDate" @plusMonth="plusMonth" @minusMonth="minusMonth"></Calendar>
						</div>
					</div>
				</div>
				<img src="../../assets/eventtheme/statistics.png" alt="">
			</div>
			
		<!-- 	<div class="statistics">
				<div class="title">
					<p class="name">赛事安排</p>
				</div>
				<div class="table">
					<el-table :data="scheduleList" border style="width: 100%;"
						:header-cell-style="{ background: 'rgba(0, 173, 249, 0.38)' }"
						:row-class-name="tableRowClassName">
						<el-table-column prop="name" label="赛事名称" align="center">
						</el-table-column>
						<el-table-column prop="address" label="地点" align="center">
						</el-table-column>
						<el-table-column prop="createTime" label="时间" align="center">
							<template slot-scope="scope">
								{{scope.row.start_time | format}}
							</template>
						</el-table-column>
						<el-table-column prop="content" label="赛事介绍" align="center">
							<template slot-scope="scope">
								<div v-html="scope.row.content"></div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<img src="../../assets/eventtheme/statistics.png" alt="">
			</div> -->

			<div class="statistics" v-if="allData1 && allData1.length > 0" v-for="(item,index) in allData1"
				:key="index">
				<div v-for="(res,index1) in item" :key="index1" v-if="item && item.length > 0">
					<div class="title">
						<p class="name">{{res.name}}</p>
						<p class="date border" @click="goDingyue(2,res.name,res)">查看所有</p>
					</div>
					<div class="wonderful-box" style="margin-top: 145PX;" v-if="res.name == '精彩瞬间' || res.name == '精彩图集'">
						<div class="wonderful" v-for="(ess,index2) in res.articles" v-if="index2<4" :key="index2"
							@click="jump(ess.id)">
							<p class="name">{{ess.title}}</p>
							<img class="img" :src="ess.imgUrl" alt="">
						</div>
					</div>
					<div class="project-case" v-else-if="res.name == '竞赛项目'">
						<div class="project-box" v-for="(ess,index3) in res.articles" v-if="index3<6" :key="index3"
							@click="jump(ess.id)">
							<div class="project">
								<img :src="ess.imgUrl" alt="">
								<div class="title-box">
									<p class="name">{{ess.title}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="specific" v-else>
						<ul>
							<li v-for="(ess,index) in res.articles" v-if="index<11" :key="index">
								<div class="rounddot">
									<div class="round"></div>
									<a class="rounddota" @click="jump(ess.id)">
										{{ess.title}}
									</a>
								</div>
								<span>[{{ess.publishTime | format}}]</span>
							</li>
						</ul>
					</div>
					<img src="../../assets/eventtheme/statistics2.png" alt="" v-if="res.name == '精彩瞬间' || res.name == '精彩图集'">
					<img src="../../assets/eventtheme/statistics.png" alt="" v-else>
				</div>
			</div>
			<div style="height: 100PX;"></div>
		</div>

		<div v-else>
			<div class="statistics">
				<div class="title">
					<p class="name">{{concentratetitle}}</p>
				</div>
				<div class="specific">
					<ul>
						<li v-for="(item,index) in concentrateList" :key="index">
							<div class="rounddot">
								<div class="round"></div>
								<a class="rounddota" @click="jump(item.id)">
									{{item.title}}
								</a>
							</div>
							<span>[{{item.publishTime | format}}]</span>
						</li>
					</ul>
					<div class="paging" v-if="concentrateList.length > 0">
						<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10"></pagination>
					</div>
				</div>
				<img src="../../assets/eventtheme/statistics.png" alt="">
			</div>
			<div style="height: 100PX;"></div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	import carousel from '@/components/carousel.vue'
	import achievementimg from '../../assets/eventtheme/rectangle.png'
	import achievementimg1 from '../../assets/eventtheme/rectangle1.png'
	import Calendar from '@/components/calendar.vue'
	export default {
		name: 'eventTheme2',
		components: {
			Calendar,
			carousel
		},
		data() {
			return {
				achievement: achievementimg,
				achievement1: achievementimg1,
				tableData: [],
				allData: [],
				allData1: [],
				articles: [],
				navindex: 1,
				cards: [],
				mchList: [],
				statisticalTime: '',
				display: true,
				concentrateList: [],
				concentratetitle: '',
				navList: [],
				info: {
					id: '',
					pageNum: 1,
					pageSize: 10,
				},
				total: 0,
				topImg: '',

				scrollIndex: 1,
				$lunbo: null,
				$circle: null,
				$main: null,
				isAnimated: false,
				len: 0,
				arr: [],
				infoDate:[],
				scheduleList:[]
			}
		},
		created() {
			
			document.title = this.$route.query.title + '-北京市体育竞赛管理和国际交流中心'
			this.$meta().refresh()
			
			if (!localStorage.getItem('isReloaded')) {
				localStorage.setItem('isReloaded', true);
				location.reload();
			} else {
				localStorage.removeItem('isReloaded');
			}
			this.getdata()
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
			this.getIndexImgList()
			this.getTopBanner()
			this.getNav()
			this.getIndexColumn()
			this.medalRankingList()
			// this.arrange()
		},
		activated() {
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
		},
		activated() {
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
		},
		mounted() {
			
		},
		computed: {
			getNowDate() {
				const timeOne = new Date()
				const year = timeOne.getFullYear()
				let month = timeOne.getMonth() + 1
				let day = timeOne.getDate()
				month = month < 10 ? '0' + month : month
				day = day < 10 ? '0' + day : day
				const NOW_MONTHS_AGO = `${year}-${month}-${day}`
				return NOW_MONTHS_AGO
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			getdata(){
				const timeOne = new Date()
				const year = timeOne.getFullYear()
				let month = timeOne.getMonth() + 1
				let day = timeOne.getDate()
				month = month < 10 ? '0' + month : month
				day = day < 10 ? '0' + day : day
				const NOW_MONTHS_AGO = `${year}-${month}`
				let data = {
					stationId:this.$route.query.id
				}
				this.$api.matchInfoDateOther(data).then(res=>{
					this.matchInfoDate(res.data.data[0])
				})
			},
			getIndexImgList() {
				this.$api.getIndexImgList().then((res) => {
					this.cards = res.data.data
				});
			},
			getTopBanner() {
				this.$api.getTopBanner({
					type: '1'
				}).then(res => {
					this.topImg = res.data.data[0].imgUrl
					console.log(res.data.data[0].imgUrl)
				})
			},
			getNav() {
				this.$api.getNav().then(res => {
					this.navList = res.data.data
				})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (String(rowIndex / 2).indexOf(".") > -1) {
					return 'success-row';
				}
				return '';
			},
			getnav(index, item) {
				this.navindex = index
				this.articles = item.articles
				this.articles.forEach(item=>{
					item.title= item.title.replace(/<br>/g, ' ')
				})
			},
			matchInfoDate(e){
				let data = {
					startTime:e,
					stationId:this.$route.query.id
				}
				this.$api.matchInfoDate(data).then(res=>{
					this.infoDate = res.data.data
					this.getDate(this.infoDate[0] || e)
				})
			},
			getDate(e) {
				let data = {
					stationId: this.$route.query.id,
					startTime: e
				}
				this.$api.matchInfoList(data).then(res => {
					res.data.data.forEach(item => {
					  item.isJSON = this.isJSON(item.start_time)
					  if (this.isJSON(item.start_time)) {
					    item.start_time = JSON.parse(item.start_time)
					  }
					})
					this.mchList = res.data.data
				})
			},
			isJSON(str) { // 判断是否json
			  if (typeof str != 'string') { // 1、传入值必须是 字符串
			    return false;
			  }
			  try {
			    var obj = JSON.parse(str); // 2、仅仅通过 JSON.parse(str)，不能完全检验一个字符串是JSON格式的字符串
			    if (typeof obj == 'object' && obj) { //3、还必须是 object 类型
			      return true;
			    } else {
			      return false;
			    }
			  } catch (e) {
			    return false;
			  }
			  return false;
			},
			getTime(dates) { // 时间转换
			  let startDate = new Date(dates[0]);
			  let endDate = new Date(dates[1]);
			  let formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
			  let formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
			  if(formattedStartDate == formattedEndDate){
				  return `${formattedStartDate}`;
			  }else{
				  return `${formattedStartDate} 至 ${formattedEndDate}`;
			  }
			},
			plusMonth(e){
				this.matchInfoDate(e)
			},
			minusMonth(e){
				this.matchInfoDate(e)
			},
			homepage() {
				this.$router.push({
					path: '/'
				})
			},
			getIndexColumn() {
				this.$api.getIndexColumn(this.$route.query.id).then(res => {
					this.allData = res.data.data
					this.allData1 = res.data.data.slice(1)
					this.articles = res.data.data[0][0].articles
					this.articles.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
					this.allData1.forEach(item=>{
						item.forEach(rss=>{
							rss.articles.forEach(css=>{
								css.title= css.title.replace(/<br>/g, ' ')
							})
						})
					})
				})
			},
			medalRankingList() {
				this.$api.medalRankingList(this.$route.query.id,1).then(res => {
					this.tableData = res.data.data.rankingList
					this.statisticalTime = res.data.data.statisticalTime
				})
			},
			goDingyue(e, title, row) {
				this.concentratetitle = title
				if (e == 1) {
					this.display = true
					this.concentrateList = []
					this.getIndexImgList()
				} else {
					this.display = false
					this.info.id = row.id
					this.getArticleListById()
				}
			},
			currentchange(val) {
				this.info.pageNum = val
				this.getArticleListById()
			},
			getArticleListById() {
				this.$api.getArticleListById(this.info).then(res => {
					this.concentrateList = res.data.data.list
					this.total = res.data.data.total
					this.concentrateList.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			// arrange() {
			// 	let data = {
			// 		stationId: this.$route.query.id,
			// 	}
			// 	this.$api.matchInfoListAll(data).then(res => {
			// 		this.scheduleList = res.data.data
			// 	})
			// },
		}
	}
</script>
<style  lang="scss" scoped>
	.back {
		width: 100%;
		min-height: 500PX;
	}

	img {
		width: 100%;
		height: 100%;
	}

	.navigation {
		height: 164PX;
		width: 100%;
		background: #FFFFFF;
		display: flex;
		justify-content: center;
		cursor: pointer;
		padding-top: 20PX;
	}

	.piece {
		height: 129PX;
		background: #E9F5FF;
		border-radius: 0PX 0PX 30PX 30PX;
		display: flex;
		margin-right: 28PX;
		padding-right: 20PX;
	}

	.piece .court {
		width: 65PX;
		height: 50PX;
		margin-top: 36PX;
		margin-left: 29PX;
	}

	.piece .name {
		font-size: 28PX;
		font-weight: 500;
		color: #000000;
		margin-left: 24PX;
		margin-top: 42PX;
	}

	.carousel {
		width: 1200PX;
		margin: 0 auto;
		margin-top: 47PX;
		display: flex;
	}

	.lun {
		width: 643PX;
		height: 459PX;
		border-radius: 16PX;
	}

	.lun .main {
		position: relative;
		overflow: hidden;
		border-radius: 16PX;
	}

	.lun .lun_bo {
		overflow: hidden;
		position: absolute;
	}

	.lun .lun_bo:hover {
		cursor: pointer;
	}

	.lun .lun_bo img {
		display: block;
		float: left;
		border-radius: 16PX;
		height: 459PX;
	}

	.lun .btn_demo {
		width: 643PX;
		height: 72PX;
		background: rgba(0, 0, 0, 0.38);
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1000;
		padding: 0 40PX;
		justify-content: space-between;
		border-bottom-left-radius: 16PX;
		border-bottom-right-radius: 16PX;
	}

	.lun .btn_disc {
		display: flex;
		justify-content: center;
		margin-top: -110PX;
	}

	.lun .desc {
		color: #fff;
		text-align: center;
		font-size: 18PX;
		font-weight: 500;
		line-height: 72PX;
	}

	.lun a {
		width: 13PX;
		height: 13PX;
		background: #FFFFFF;
		border-radius: 50%;
		display: flex;
		margin-right: 10PX;
		cursor: pointer;
		margin-top: 13PX;
	}

	.lun a:hover {
		cursor: pointer;
	}

	.lun .direct {
		background: transparent;
		position: absolute;
		top: 200PX;
		height: 30PX;
		line-height: 25PX;
		text-align: center;
		font-size: 40PX;
		padding: 3PX;
		border: 4PX solid #fff;
		color: #fff;
		font-weight: 700;
		display: none;
	}

	.lun .left {
		left: 70PX;
	}

	.lun .right {
		right: 70PX;
	}

	.lun .direct:hover {
		cursor: pointer;
	}

	.block_8 {
		width: 532PX;
		height: 379PX;
		margin-left: 31PX;
	}

	.group_8 {
		height: 48PX;
		display: flex;
		margin-left: 30PX;
	}

	.rectangle {
		width: 218PX;
		height: 48PX;
		cursor: pointer;
		margin-right: 27PX;
	}

	.rectangle img {
		width: 100%;
		height: 100%;
	}

	.rectangle .name {
		width: 218PX;
		height: 48PX;
		font-size: 20PX;
		font-weight: 500;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.left {
		margin-left: 27PX;
	}

	.box {
		background: #FFFFFF;
		border-radius: 25PX;
		padding: 14PX;
		padding-top: 38PX;
		padding-bottom: 38PX;
	}

	.text-wrapper_2 {
		height: 42PX;
		margin-left: 1PX;
		width: 96PX;
		line-height: 42PX;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1PX solid #D0D0D0;
	}

	.text_7 {
		width: 64PX;
		height: 17PX;
		overflow-wrap: break-word;
		color: rgba(51, 51, 51, 1);
		font-size: 16PX;
		font-family: MicrosoftYaHei-Bold;
		font-weight: bold;
		text-align: left;
		white-space: nowrap;
		line-height: 16PX;
	}

	.group_9 {
		background-color: rgba(208, 208, 208, 1);
		width: 528PX;
		height: 1PX;
	}

	.box_31 {
		width: 503PX;
		height: 19PX;
		margin-top: 15PX;
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: space-between;
	}

	.thumbnail_8 {
		width: 12PX;
		height: 12PX;
	}

	.text_20 {
		height: 17PX;
		overflow-wrap: break-word;
		color: rgba(51, 51, 51, 1);
		font-size: 15PX;
		font-family: MicrosoftYaHei;
		font-weight: NaN;
		text-align: left;
		white-space: nowrap;
		line-height: 16PX;
		margin: 1PX 0 0 13PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}
	.text_20:hover a {
		color: #408ED6;
	}

	.text_21 {
		height: 14PX;
		overflow-wrap: break-word;
		color: rgba(153, 153, 153, 1);
		font-size: 13PX;
		font-family: MicrosoftYaHei;
		font-weight: NaN;
		text-align: left;
		white-space: nowrap;
		line-height: 13PX;
		margin: 1PX 0 0 20PX;
	}

	.image_9 {
		border: 1PX dashed #CCD5D5;
		margin-top: 12PX;
	}

	.image_8 {
		background-color: rgba(208, 208, 208, 1);
		height: 1PX;
	}

	.statistics {
		width: 1200PX;
		margin: 0 auto;
		margin-top: 67PX;
	}

	.statistics img {
		width: 100%;
	}

	.statistics .title {
		display: flex;
		position: absolute;
		justify-content: space-between;
		align-items: center;
		width: 1200PX;
	}

	.statistics .title .name {
		font-size: 30PX;
		font-weight: bold;
		color: #0056F0;
		margin-top: 40PX;
		margin-left: 120PX;
	}

	.statistics .title .date {
		margin-top: 45PX;
		margin-right: 48PX;
		font-size: 21PX;
		font-weight: 500;
		color: #0077EC;
	}

	.statistics .title .border {
		border: 1PX solid #0084F8;
		border-radius: 15PX;
		padding: 4PX 15PX;
		margin-top: 40PX !important;
		cursor: pointer;
		z-index: 999999;
	}

	.table {
		width: 1160PX !important;
		position: absolute;
		margin-top: 137PX;
		padding-left: 38PX;
		padding-right: 25PX;
		width: 1200PX;
		height: 424PX;
		overflow-y: auto;
		color: #000;
	}

	.table ::v-deep .el-table {
		border-radius: 25PX;
	}

	.table ::v-deep .el-table .success-row {
		background: rgba(0, 119, 236, 0.12);
	}

	/*滚动条样式*/
	.table::-webkit-scrollbar {
		width: 17PX;
		position: absolute;
	}

	.table::-webkit-scrollbar-thumb {
		border-radius: 10PX;
		background: rgba(0, 132, 248, 0.32);
		box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
	}

	.table::-webkit-scrollbar-track {
		border-radius: 10PX;
		background: rgba(166, 166, 166, 0.08);

	}

	.custom-header {
		display: flex;
		align-items: center;
	}

	.custom-header img {
		width: 36PX;
		height: 49PX;
		margin-right: 7PX;
	}
	
	.table2 {
		width: 1160PX !important;
		position: absolute;
		margin-top: 137PX;
		padding-left: 38PX;
		padding-right: 25PX;
		width: 1200PX;
		height: 490PX;
		overflow-y: auto;
		color: #000;
	}
	
	.table2 ::v-deep .el-table {
		border-radius: 25PX;
	}
	
	.table2 ::v-deep .el-table .success-row {
		background: rgba(0, 119, 236, 0.12);
	}
	
	/*滚动条样式*/
	.table2::-webkit-scrollbar {
		width: 17PX;
		position: absolute;
	}
	
	.table2::-webkit-scrollbar-thumb {
		border-radius: 10PX;
		background: rgba(0, 132, 248, 0.32);
		box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
	}
	
	.table2::-webkit-scrollbar-track {
		border-radius: 10PX;
		background: rgba(166, 166, 166, 0.08);
	
	}
	.table2 ::v-deep .el-table thead tr th:nth-child(2){
		background-image: linear-gradient(#F8B65C, #FFE71D, #FFBA5B) !important;
	}
	.table2 ::v-deep .el-table thead tr th:nth-child(3){
		background-image: linear-gradient(#EEEDEC, #D1CCCA, #F0EFEE) !important;
	}
	.table2 ::v-deep .el-table thead tr th:nth-child(4){
		background-image: linear-gradient(#FFE5BB, #DE9D76, #FFF7D9) !important;
	}

	.table2 ::v-deep .el-table thead tr {
		color: #000;
	}

	.table2 ::v-deep .el-table__body td {
		color: #000;
	}

	.project-case {
		width: 1300PX;
		position: absolute;
		padding: 105PX 73PX 70PX 62PX;
	}

	.project-box {
		width: calc(100% - 92PX);
		height: 67PX;
		margin-bottom: 17PX;
		margin-right: 61PX;
		border-radius: 15PX;
		background: linear-gradient(to bottom, #F0F7FC, #E2F0FA);
	}

	.project {
		display: flex;
	}

	.project img {
		width: 41.7PX;
		height: 41.7PX;
		margin-top: 12PX;
		margin-left: 17PX;
		border-radius: 10PX;
		flex-shrink: 0;
	}

	.project .title-box {
		margin-left: 37PX;
		margin-top: 17PX;
		padding-right: 25PX;
	}

	.project .name {
		font-size: 25PX;
		font-weight: 500;
		color: #000000;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 960PX;
		cursor: pointer;
	}

	.project .brief {
		height: 40PX;
		font-size: 14PX;
		font-weight: 400;
		color: #000000;
		margin-top: 5PX;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.calendar-box {
		position: absolute;
		padding-top: 112PX;
	}

	.calendar-box .calendar {
		display: flex;
	}

	.calendar-box .calendar .flrex {
		margin-left: 39PX;
		margin-top: 100PX;
		overflow-y: auto;
		height: 298PX;
		width: 500PX;
	}

	.calendar-box .calendar .flrex .information .rubric {
		width: 460PX;
		height: 106PX;
		font-size: 26PX;
		font-weight: bold;
		color: #0086F8;
		background: rgba(0, 132, 248, 0.14);
		border-radius: 14PX;
		 background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(0, 132, 248, 0.14));
	}
	.rubricbox{
		height: 92PX;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		padding-top: 20PX;
		margin-left: 65PX;
		margin-right: 54PX;
	}

	.calendar-box .calendar .flrex .information .date {
		font-size: 27PX;
		font-weight: 400;
		color: #2F3F5A;
		margin-top: 41PX;
		display: flex;
		align-items: center;
		margin-left: 68PX;
	}

	.calendar-box .calendar .flrex .information .round {
		width: 12PX;
		height: 12PX;
		background: rgba(0, 0, 0, 0.28);
		border-radius: 50%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 390PX;
	}

	.calendar-box .calendar .flrex .information .location {
		font-size: 27PX;
		font-weight: 400;
		color: #2F3F5A;
		margin-top: 29PX;
		display: flex;
		align-items: center;
		margin-left: 68PX;
		padding-bottom: 50PX;
	}
	.roundtext{
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 390PX;
	}

	.calendar-box .calendar .flrex .information .round {
		width: 12PX;
		height: 12PX;
		background: rgba(0, 0, 0, 0.28);
		border-radius: 50%;
	}

	/*滚动条样式*/
	.calendar-box .calendar .flrex::-webkit-scrollbar {
		width: 18PX;
		position: absolute;
	}

	.calendar-box .calendar .flrex::-webkit-scrollbar-thumb {
		border-radius: 8PX;
		background: #0086F8;
	}

	.calendar-box .calendar .flrex::-webkit-scrollbar-track {
		border-radius: 8PX;
		background: rgba(68, 68, 68, 0.14);

	}

	.calendar-box .calendar .assembly {
		padding-left: 40PX;
	}

	.specific {
		width: 1156PX;
		font-size: 15PX;
		color: #333333;
		position: absolute;
		margin-top: 134PX;
		padding: 0 67PX 0 53PX;
		height: 450PX;
		overflow-y: auto
	}

	.specific::-webkit-scrollbar {
		width: 17PX;
		position: absolute;
	}

	.specific::-webkit-scrollbar-thumb {
		border-radius: 10PX;
		background: rgba(0, 132, 248, 0.32);
		box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
	}

	.specific::-webkit-scrollbar-track {
		border-radius: 10PX;
		background: rgba(166, 166, 166, 0.08);

	}

	.specific ul li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 23PX;
	}

	.specific ul li span {
		color: #999999;
	}

	.rounddot {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.rounddota {
		width: 900PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}
	.rounddot:hover a {
		color: #408ED6;
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;

	}

	.wonderful-box {
		display: flex;
		position: absolute;
		margin-top: 256PX;
		padding: 0 0 0 54PX;
		cursor: pointer;
	}

	.wonderful {
		width: 251PX;
		height: 171PX;
		margin-right: 27PX;
		border-radius: 16PX;
		border-radius: 16PX;
	}

	.wonderful .img {
		width: 100%;
		height: 100%;
		border-radius: 16PX;
		border-radius: 16PX;
	}

	.wonderful .name {
		width: 251PX;
		background: rgba(0, 0, 0, 0.55);
		font-size: 15PX;
		font-weight: bold;
		color: #F8F9FD;
		border-bottom-left-radius: 16PX;
		border-bottom-right-radius: 16PX;
		position: absolute;
		padding: 20PX 10PX;
		bottom: 0;
		text-align: center;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.return {
		right: 42PX;
		width: 90PX;
		height: 206PX;
		background: #D5ECFF;
		border-radius: 15PX;
		cursor: pointer;
		z-index: 999999;
		
		top: 50%;
		transform: translateY(-50%);
		position: fixed;
	}

	.return img {
		width: 61PX;
		height: 58PX;
		margin-top: 23PX;
		margin-left: 18PX;
	}

	.return img {
		width: 61PX;
		height: 58PX;
		margin-top: 23PX;
		margin-left: 18PX;
	}

	.return .home {
		font-size: 25PX;
		font-weight: 400;
		color: #000000;
		margin-left: 20PX;
		margin-top: 24PX;
	}

	.paging {
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
	}
</style>
